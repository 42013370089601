/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'ChronicleDisplay-Black';
    src: url('../fonts/Chronicle Display Black.eot');
	src: url('../fonts/Chronicle Display Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Chronicle Display Black.woff2') format('woff2'),
		url('../fonts/Chronicle Display Black.woff') format('woff'),
		url('../fonts/Chronicle Display Black.svg#Chronicle Display Black') format('svg');
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'ChronicleDisplay-Bold';
    src: url('../fonts/Chronicle Display Bold.eot');
	src: url('../fonts/Chronicle Display Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Chronicle Display Bold.woff2') format('woff2'),
		url('../fonts/Chronicle Display Bold.woff') format('woff'),
		url('../fonts/Chronicle Display Bold.svg#Chronicle Display Bold') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'ChronicleDisplay-Light';
    src: url('../fonts/Chronicle Display Light.eot');
	src: url('../fonts/Chronicle Display Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Chronicle Display Light.woff2') format('woff2'),
		url('../fonts/Chronicle Display Light.woff') format('woff'),
		url('../fonts/Chronicle Display Light.svg#Chronicle Display Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'ChronicleDisplay-Semi';
    src: url('../fonts/Chronicle Display Semibold.eot');
	src: url('../fonts/Chronicle Display Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Chronicle Display Semibold.woff2') format('woff2'),
		url('../fonts/Chronicle Display Semibold.woff') format('woff'),
		url('../fonts/Chronicle Display Semibold.svg#Chronicle Display Semibold') format('svg');
    font-weight: 375;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'FreightBigProBold-Regular';
    src: url('../fonts/FreightBig Pro Bold.eot');
	src: url('../fonts/FreightBig Pro Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FreightBig Pro Bold.woff2') format('woff2'),
		url('../fonts/FreightBig Pro Bold.woff') format('woff'),
		url('../fonts/FreightBig Pro Bold.svg#FreightBig Pro Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB04;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Black Italic';
    src: url('../fonts/Gilroy Black Italic.eot');
	src: url('../fonts/Gilroy Black Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Black Italic.woff2') format('woff2'),
		url('../fonts/Gilroy Black Italic.woff') format('woff'),
		url('../fonts/Gilroy Black Italic.svg#Gilroy Black Italic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Black';
    src: url('../fonts/Gilroy Black.eot');
	src: url('../fonts/Gilroy Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Black.woff2') format('woff2'),
		url('../fonts/Gilroy Black.woff') format('woff'),
		url('../fonts/Gilroy Black.svg#Gilroy Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Bold Italic';
    src: url('../fonts/Gilroy Bold Italic.eot');
	src: url('../fonts/Gilroy Bold Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Bold Italic.woff2') format('woff2'),
		url('../fonts/Gilroy Bold Italic.woff') format('woff'),
		url('../fonts/Gilroy Bold Italic.svg#Gilroy Bold Italic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Bold';
    src: url('../fonts/Gilroy Bold.eot');
	src: url('../fonts/Gilroy Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Bold.woff2') format('woff2'),
		url('../fonts/Gilroy Bold.woff') format('woff'),
		url('../fonts/Gilroy Bold.svg#Gilroy Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-ExtraBold Italic';
//    src: url('../fonts/Gilroy ExtraBold Italic.eot');
//	src: url('../fonts/Gilroy ExtraBold Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy ExtraBold Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy ExtraBold Italic.woff') format('woff'),
//		url('../fonts/Gilroy ExtraBold Italic.svg#Gilroy ExtraBold Italic') format('svg');
//    font-weight: 800;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-ExtraBold';
//    src: url('../fonts/Gilroy ExtraBold.eot');
//	src: url('../fonts/Gilroy ExtraBold.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy ExtraBold.woff2') format('woff2'),
//		url('../fonts/Gilroy ExtraBold.woff') format('woff'),
//		url('../fonts/Gilroy ExtraBold.svg#Gilroy ExtraBold') format('svg');
//    font-weight: 800;
//    font-style: normal;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Heavy Italic';
//    src: url('../fonts/Gilroy Heavy Italic.eot');
//	src: url('../fonts/Gilroy Heavy Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Heavy Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy Heavy Italic.woff') format('woff'),
//		url('../fonts/Gilroy Heavy Italic.svg#Gilroy Heavy Italic') format('svg');
//    font-weight: 900;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Heavy';
//    src: url('../fonts/Gilroy Heavy.eot');
//	src: url('../fonts/Gilroy Heavy.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Heavy.woff2') format('woff2'),
//		url('../fonts/Gilroy Heavy.woff') format('woff'),
//		url('../fonts/Gilroy Heavy.svg#Gilroy Heavy') format('svg');
//    font-weight: 900;
//    font-style: normal;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Light Italic';
//    src: url('../fonts/Gilroy Light Italic.eot');
//	src: url('../fonts/Gilroy Light Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Light Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy Light Italic.woff') format('woff'),
//		url('../fonts/Gilroy Light Italic.svg#Gilroy Light Italic') format('svg');
//    font-weight: 300;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Light';
    src: url('../fonts/Gilroy Light.eot');
	src: url('../fonts/Gilroy Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Light.woff2') format('woff2'),
		url('../fonts/Gilroy Light.woff') format('woff'),
		url('../fonts/Gilroy Light.svg#Gilroy Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

///@font-face {
///    font-family:'Gilroy-Medium Italic';
///    src: url('../fonts/Gilroy Medium Italic.eot');
///	src: url('../fonts/Gilroy Medium Italic.eot?#iefix') format('embedded-opentype'),
///		url('../fonts/Gilroy Medium Italic.woff2') format('woff2'),
///		url('../fonts/Gilroy Medium Italic.woff') format('woff'),
///		url('../fonts/Gilroy Medium Italic.svg#Gilroy Medium Italic') format('svg');
///    font-weight: 500;
///    font-style: italic;
///    font-stretch: normal;
///    unicode-range: U+0020-FB02;
///}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Medium';
//    src: url('../fonts/Gilroy Medium.eot');
//	src: url('../fonts/Gilroy Medium.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Medium.woff2') format('woff2'),
//		url('../fonts/Gilroy Medium.woff') format('woff'),
//		url('../fonts/Gilroy Medium.svg#Gilroy Medium') format('svg');
//    font-weight: 500;
//    font-style: normal;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-SemiBold Italic';
//    src: url('../fonts/Gilroy SemiBold Italic.eot');
//	src: url('../fonts/Gilroy SemiBold Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy SemiBold Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy SemiBold Italic.woff') format('woff'),
//		url('../fonts/Gilroy SemiBold Italic.svg#Gilroy SemiBold Italic') format('svg');
//    font-weight: 600;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-SemiBold';
    src: url('../fonts/Gilroy SemiBold.eot');
	src: url('../fonts/Gilroy SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy SemiBold.woff2') format('woff2'),
		url('../fonts/Gilroy SemiBold.woff') format('woff'),
		url('../fonts/Gilroy SemiBold.svg#Gilroy SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Thin Italic';
//    src: url('../fonts/Gilroy Thin Italic.eot');
//	src: url('../fonts/Gilroy Thin Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Thin Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy Thin Italic.woff') format('woff'),
//		url('../fonts/Gilroy Thin Italic.svg#Gilroy Thin Italic') format('svg');
//    font-weight: 250;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
//
//@font-face {
//    font-family:'Gilroy-Thin';
//    src: url('../fonts/Gilroy Thin.eot');
//	src: url('../fonts/Gilroy Thin.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy Thin.woff2') format('woff2'),
//		url('../fonts/Gilroy Thin.woff') format('woff'),
//		url('../fonts/Gilroy Thin.svg#Gilroy Thin') format('svg');
//    font-weight: 250;
//    font-style: normal;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
///* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

//@font-face {
//    font-family:'Gilroy-UltraLight Italic';
//    src: url('../fonts/Gilroy UltraLight Italic.eot');
//	src: url('../fonts/Gilroy UltraLight Italic.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy UltraLight Italic.woff2') format('woff2'),
//		url('../fonts/Gilroy UltraLight Italic.woff') format('woff'),
//		url('../fonts/Gilroy UltraLight Italic.svg#Gilroy UltraLight Italic') format('svg');
//    font-weight: 250;
//    font-style: italic;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}
//
/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

//@font-face {
//    font-family:'Gilroy-UltraLight';
//    src: url('../fonts/Gilroy UltraLight.eot');
//	src: url('../fonts/Gilroy UltraLight.eot?#iefix') format('embedded-opentype'),
//		url('../fonts/Gilroy UltraLight.woff2') format('woff2'),
//		url('../fonts/Gilroy UltraLight.woff') format('woff'),
//		url('../fonts/Gilroy UltraLight.svg#Gilroy UltraLight') format('svg');
//    font-weight: 250;
//    font-style: normal;
//    font-stretch: normal;
//    unicode-range: U+0020-FB02;
//}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Gilroy-Regular';
    src: url('../fonts/Gilroy Regular.eot');
	src: url('../fonts/Gilroy Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy Regular.woff2') format('woff2'),
		url('../fonts/Gilroy Regular.woff') format('woff'),
		url('../fonts/Gilroy Regular.svg#Gilroy Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'y';
    src: url('../fonts/y RegularItalic.eot');
	src: url('../fonts/y RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/y RegularItalic.woff2') format('woff2'),
		url('../fonts/y RegularItalic.woff') format('woff'),
		url('../fonts/y RegularItalic.svg#y RegularItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}
