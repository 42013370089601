@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'slick';
@import 'font-awesome';

/* skeleton */
%after-clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

%before-square {
  position: relative;
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;

  >div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.table {
  display: table;
  height: 100%;
  width: 100%;

  >div {
    display: table-cell;
  }
}

%page-element {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-width;
  padding-left: $default-gutter;
  padding-right: $default-gutter;

  >ul {
    margin-left: -$default-gutter;
    margin-right: -$default-gutter;
  }
}

%middle-absolute {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* skeleton */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  background-position: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: cover;
  outline: none;
}

body,
html {
  min-width: 300px;
  background-color: #FFF;
  color: #000;
  width: 100%;
  display: block;
  height: 100%;
  /* Po vyvoji odkomentovat */
  /*overflow-x: hidden;
  max-width: 100%;*/
}

body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* Jsem rodič: */
  font-family: Gilroy-Regular;
  font-size: 13px;
}

a {
  text-decoration: none;
  border-color: transparent;
  cursor: pointer;

  &:hover,
  :active {
    border: none;
    border-color: transparent;
    cursor: pointer;
  }

  &.link {
    vertical-align: baseline;
    text-decoration: underline;
  }

  &.active {
    text-decoration: underline;
  }

  & *,
  &>* {
    text-decoration: none;
    border: none;
    border-width: 0;
    border-color: transparent;
  }
}

mark,
strong {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: bold;
  background-color: transparent;
}

.translation-helper {
  vertical-align: inherit;
  font-size: 63%;
  opacity: .8;
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
}

h1,
.h1 {
  /* Bydlení daleko od vš: */
  font-family: ChronicleDisplay-Semi;
  font-size: 45px;
  letter-spacing: 0.02em;
  line-height: 1.288em;
  padding-bottom: 20px;
  padding-top: .88em;
  display: block;
  max-width: 100%;

  @media screen and (max-width: 1300px) {
    font-size: 42px;
  }

  @media screen and (max-width: 1100px) {
    font-size: 26px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }

  @media screen and (max-width: l§) {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 1.21em
  }

  .translation-helper {
    font-size: 40%;
    display: block;
    line-height: 1.3em;
  }
}

h2,
.h2 {
  /* Šumavské Stráně: */
  font-family: ChronicleDisplay-Semi;
  font-size: 30px;
  letter-spacing: 0.0333em;
  line-height: 1.3;
  position: relative;
  display: block;
  padding-bottom: 0.633em;
  will-change: unset;

  span {
    position: relative;
    padding-bottom: 2em;
    padding-top: 1.3em;
    padding-left: 1.3em;
    display: block;

    &.only-span {
      padding-left: 0;
      padding-bottom: 1em;
      padding-top: 0;
    }
  }

  bdi,
  svg {
    /* 02.: */
    position: absolute;
    font-family: FreightBigProBold-Regular;
    font-size: 180px;
    color: #F0E9E3;
    letter-spacing: 0;
    line-height: .5em;
    top: 26px;
    transform: translate(0, -36px);
    will-change: unset;
    transform-origin: left 10%;

    &:before {
      content: '';
      display: block;
      width: 71px;
      height: 3px;
      background: #F0E9E3;
      margin-left: .58em;
    }
  }

  .center & {

    bdi,
    svg {
      transform: translate(0, -36px);
      transform-origin: center 30%;
      left: 0;
      right: 0;
      margin: auto;
    }

    span {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 27px;

    bdi,
    svg {

      .center &,
      & {
        transform: translate(0, -33px) scale(.9);
      }
    }
  }

  @media screen and (max-width: 1050px) {
    font-size: 26px;
    bdi,
    svg {
      .center &,
      & {
        transform: translate(0, -28px) scale(.8);
      }
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 25px;

    bdi,
    svg {

      .center &,
      & {
        transform: translate(0, -26px) scale(.6);
      }
    }
  }
}

h3,
.h3 {
  font-family: ChronicleDisplay-Light;
  font-size: 18px;
  letter-spacing: 0.033em;
  line-height: 1.566;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

h4,
.h4 {
  font-family: Gilroy-SemiBold;
  font-size: 11px;
  letter-spacing: 0.18em;
  padding-bottom: 1.3em;
  @media screen and (max-width: 340px) {
    font-size: 10px;
    letter-spacing: 0.16em;
  }
}

h5,
.h5 {
  font-family: Gilroy-Bold;
  font-size: 9px;
  color: #000000;
  letter-spacing: 0.3888em;
  text-transform: uppercase;
}

p,
.p {
  /* Šumavské Stráně char: */
  font-family: Gilroy-Regular;
  font-size: 14px;
  color: #4A4A4A;
  letter-spacing: 0.033em;
  line-height: 1.357;
  padding-bottom: 1.3em;

  strong,
  mark,
  a {
    vertical-align: baseline;
  }

  a {
    text-decoration: underline;
  }
}

.max-550 {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.max-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

span {

  strong,
  mark,
  a,
  span {
    vertical-align: baseline;
  }
}

$twobecameone: 600px;
$threebecametwo: 600px;
$threebecameone: 400px;
$fourbecamethree: 980px;
$fourbecametwo: 600px;
$fourbecameone: 400px;

ul {
  @extend %page-element;
  padding: $default-gutter / 2;

  li {
    float: left;
    display: block;
    padding: $default-gutter / 2;
  }

  .inner {
    margin-left: -$default-gutter;
    margin-right: -$default-gutter;
  }

  &.zero {
    padding: 0;

    >li {
      padding: 0;
    }
  }

  &.two {
    >li {
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }

      @media screen and (max-width: $twobecameone) {
        width: 100%;
      }
    }
  }

  &.three {
    >li {
      width: 33.333%;

      &:nth-child(3n+1) {
        clear: left;
      }

      @media screen and (max-width: $threebecameone) {
        width: 100%;
      }
    }
  }

  &.four {
    >li {
      width: 25%;

      &:nth-child(4n+1) {
        clear: left;
      }

      @media screen and (max-width: $fourbecamethree) {
        width: 33.333%;

        &:nth-child(4n+1) {
          clear: none;
        }

        &:nth-child(3n+1) {
          clear: left;
        }
      }

      @media screen and (max-width: $fourbecametwo) {
        width: 50%;

        &:nth-child(3n+1) {
          clear: none;
        }

        &:nth-child(2n+1) {
          clear: left;
        }
      }

      @media screen and (max-width: $fourbecameone) {
        width: 100%;
      }
    }
  }

  &.reverse {}

  &.inline {
    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    >li {
      display: inline-block;
      float: none;
      line-height: 0;
      font-size: 0;
    }
  }

  &.list {
    >li {
      display: block;
      float: none;
    }
  }

  @extend %after-clear;
}

.flexbox-wrapper {
  display: flex;
  align-content: stretch;
  flex-flow: row wrap;
  align-items: center;

  &.two {
    >div {
      width: 50%;
    }

    @media screen and (max-width: $twobecameone) {
      >div {
        min-width: 100%;
      }
    }

    &--no-responsive {
      >div {
        width: 50%;
      }
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: bottom;
  }

  &.stretch {
    align-items: stretch;
  }

  //&.distribution{
  //    @include distribution(div)
  //}
  &.justify-content {
    justify-content: space-between;
  }

  >div {
    flex: 1;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: none;

  ul {}

  li {}

  a {
    @include animation(all);

    &:hover {}
  }
}

#menu {
  //display: none;
  position: fixed;
  top: -100vh;
  left: 0%;
  height: 100%;
  height: 100vh;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  z-index: 20;
  width: 100%;
}

#hamburger {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
  width: 23px;
  height: 30px;
  position: absolute;
  margin: 0 auto; //transform: rotate(0deg);
  @include animation(all, .5s, ease-in-out);
  cursor: pointer;
  right: 30px;
  top: 15px;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 18px;
    background: #333;
    border-radius: 6px;
    opacity: 1;
    left: 0; //transform: rotate(0deg);
    @include animation(all, .25s, ease-in-out)
  }

  span:nth-child(1) {
    top: 6px
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 12px
  }

  span:nth-child(4) {
    top: 18px
  }
}

.open {
  #hamburger {
    span:nth-child(1) {
      top: 12px;
      width: 0;
      left: 50%
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 18px;
      width: 0;
      left: 50%
    }
  }
}

header {
  text-align: center;
}

section {
  max-width: 100%;
  @include section-size(padding-top);
  @include section-size(padding-bottom);
  overflow-x: hidden;

  >div {
    @extend %page-element;
  }

  &.no-top-padding {
    @include section-size(padding-top, 0, 0);
  }

  &.no-bottom-padding {
    @include section-size(padding-bottom, 0, 0);
  }
}

footer {
  position: relative;
  height: 10%;
  padding-top: 20vh;
  padding-bottom: 35vh;
  display: block;
  text-align: center;
}

#bottom-page {
  height: 50px;
}

/* -- controls -- */
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  @extend .btn;
}

.btn {
  display: inline-block;
  cursor: pointer;
  position: relative;
  @include animation(all);
  /* Rectangle 3 Copy: */
  background: #B08D71;
  color: #FFF;
  padding: 15px 20px;
  font-family: Gilroy-Bold;
  font-size: 13px;
  letter-spacing: 0.38em;

  .hover &:hover {
    background: rgb(138, 109, 85);
  }

  &--small {
    @extend .h4;
    padding: 7px 12px;
    font-size: 11px;
    letter-spacing: 0;
  }

  &.reverse {
    .hover &:hover {}
  }

  &.blue {
    .hover &:hover {}
  }

  @media screen and (max-width: 980px) {}
}

textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  /* Rectangle 3: */
  border: 1px solid #cacaca;
  border-radius: 4px;
  /* Dobrý den, mám zájem: */
  font-family: Gilroy-Regular;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.033em;
  line-height: 1.5;
  padding: 6px 5px;
  width: 100%
}

input {

  &[type=text],
  &[type=email],
  &[type=tel],
  &[type=password] {
    @extend textarea;
  }

  &[type=submit] {
    @extend .btn;
  }

  &[type=file] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.000001;
  }
}

.select {
  border: 1px solid #FF0;
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: '';
    width: 0;
    height: 4px;
    border-style: solid;
    border-width: 9px 4px 0 4px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    right: 10px;
    margin: auto;
    top: 7px;
    bottom: 0;
  }

  select {
    background: none;
    border: none;
    width: calc(100% + 15px);
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    outline: none;
  }
}

label {
  display: block;

  a {
    text-decoration: underline;

    .hover &:hover {
      color: $buk;
    }
  }
}

.textbox,
.textarea {
  padding-bottom: 15px;

  label {
    @extend .h4;
    padding-bottom: 10px;
  }

  span {
    display: block;
    @extend .h4;
    padding: 3px 0 0 0px;
    font-size: 10px;
    color: #bc4438;
  }

  input {
    width: 100%;
    display: block;
  }
}

.checkbox {
  label {}

  span {
    //display: block;
  }

  input {
    display: inline-block;
    margin-right: 3px;
  }

  .error {
    display: block;
    @extend .h4;
    padding: 3px 0 0 0px;
    font-size: 10px;
    color: #bc4438;
  }
}

.selectbox {
  label {}

  select {
    display: block;
  }

  span {}
}

#lean_overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  display: none;
}

.modal {
  @extend %page-element;
  @extend %middle-absolute;
  max-height: 600px;
  height: 99px;
}

sup {
  font-size: 70%;
  line-height: 80%;
  vertical-align: top;
}

.menu {
  position: relative;
  background: $primary-color;
  color: #FFF;
  height: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  overflow: hidden;

  &__content {
    min-height: 100%;

    @media screen and (min-width: 1400px) {
      max-width: 80%;
    }
  }

  &__bottom-bar {
    @extend %page-element;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    font-family: Gilroy-Regular;
    font-size: 10px;
    color: #FFFFFF;
    white-space: nowrap;
    @media screen and (max-width: 405px) {
      flex-direction: column;
    }

    .inline {
      display: inline-block;

      /* Smluvní podmínky: */
      a {
        opacity: .5;
        @include animation(opacity);

        .hover &:hover {
          opacity: 1;
        }
      }

      span {
        opacity: .5;
        @include animation(opacity);
      }
    }

    @media screen and (min-width: 1400px) {
      max-width: calc(100% - 80px);
    }
  }

  &__small-link {
    font-family: Gilroy-Regular;
    font-size: 10px;
    color: #FFFFFF;
    letter-spacing: 0.42px;
  }

  &__small-line-link {
    @extend .h5;
    color: #FFF;
    font-size: 8px;
    letter-spacing: 1px;

    &--contact{
      display: none !important;
      @media screen and (max-width: 660px){
        display: block !important;
      }
    }

    &:before {
      content: '';
      height: 1px;
      background: #FFF;
      width: 15px;
      transform: scaleX(.66);
      display: inline-block;
      vertical-align: middle;
      transform-origin: right;
      margin-right: 9px;
      vertical-align: top;
      @include animation(transform);
    }

    .hover &:hover:before {
      transform: scaleX(1);
    }
  }

  .flexbox-wrapper>&__small-links {
    flex: 2;
    text-align: right;
  }

  &__content {
    @extend %page-element;

    &__main-links {
      font-family: ChronicleDisplay-Semi;
      padding-left: 20%;
      max-width: 30vw;
      z-index: 40;
      position: relative;

      @media screen and (max-width: 1000px) {
        margin: 0;
        padding-left: 10%;
      }
    }
  }

  &__appartment-link {
    /* Home: */
    font-size: 50px;
    letter-spacing: 0.022em;
    line-height: 1;
    opacity: .3;
    padding-bottom: 9px;
    white-space: nowrap;
    @include animation(opacity);

    @media screen and (max-width: 1200px) {
      font-size: 40px;
    }

    &--contact {
      font-size: 26px;
      padding-top: 1.5em;
      display: block;
      opacity: .3;
      @include animation(opacity);

      .hover &:hover,
      &.active {
        opacity: 1;
      }
    }

    .hover &:hover,
    &.active {
      opacity: 1;
    }

    &__number {
      /* 00: */
      font-family: FreightBigProBold-Regular;
      font-size: 14.4px;
      letter-spacing: 0;
      vertical-align: top;
      margin-right: 1.6em;
    }

    &__text {
      display: inline-block;

      .translation-helper {
        display: block;
      }
    }
  }

  @media screen and (max-width: 820px) {
    padding-top: 50px;
    padding-bottom: 160px;

    &__bottom-bar {
      bottom: 40px;
    }

    &__small-links {
      ul.inline {
        margin-right: -20px;

        &.menu__external-links {
          position: absolute;
          left: 0;
          bottom: 60px;
          text-align: left;

          >li {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 660px) {
    &__content {
      padding: 0;

      &__main-links {
        padding-left: 20px;

        &_wrapper {
          flex: 2 ! important;
        }
      }
    }

    &__appartment-link {
      font-size: 30px;

      &--contact {
        font-size: 22px;
        display: none;
      }
    }
  }

  &__photos {
    position: relative; //right: -20px;

    &_wrapper {
      position: relative;

      @media screen and (max-width: 660px) {
        width: 40%;
      }

      @media screen and (max-width: 490px) {
        width: 24%;
        top: 20px;
      }
    }

    &__pallete {
      position: relative;
    }

    &__photo {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 43vw;
      max-height: 63vh;
      width: 480px;
      height: 600px;

      @media screen and (max-width: 660px) {
        max-width: 50vw;
        max-height: 58vh;
      }

      &:first-child {
        position: relative;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .menu__side-heading {
        top: 20px;
        right: 25px;
        color: #FFF;
        transform-origin: bottom right;
        transform: translate(0, -50%) rotate(-90deg);

        .side-heading__line {
          background: #FFF;
        }
      }
    }
  }
}

.hp-header {
  position: relative;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  .slick-slider {
    max-width: 100%;
    overflow: hidden;
  }

  &--bg__slider,
  &--text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #FFF;

    &__logo {
      width: 139px;
      height: 121px;

      @media screen and (max-width: 1200px) {
        width: 125px;
        height: 109px;
      }

      @media screen and (max-height: 400px) {
        width: 111px;
        height: 97px;
      }
    }
  }

  &--text {
    .slick-slider {
      max-width: 660px;
      margin: auto;
      width: 100vw;
    }

    .relative {
      height: 100%;
    }

    p {
      color: #FFF;
      max-width: 560px;
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
    }

    svg {
      @media screen and (max-width: 660px) {
        transform-origin: bottom;
        transform: scale(.8);
      }

      @media screen and (max-width: 420px) {
        transform-origin: bottom;
        transform: scale(.7);
      }
    }
  }

  &--bg {
    max-width: 100%;
    overflow: hidden;

    .slick-slide {
      height: 100vh;
    }
  }

  .hp-header--text__slider .slick-slide {
    padding: 1px 20px;
  }

  &__arrows {}

  &__scroll-down {
    position: absolute;
    bottom: 30px;
    color: #FFF;
    cursor: pointer;
    @include page-side(right, 20);
    @extend .h4;

    svg {
      -webkit-animation: pulse 1.5s infinite;
      will-change: transform;
    }

    .bark {
      fill: #FEFEFE;
    }

    &__text {}

    @media screen and (min-width: 1400px) {
      right: 40px;
    }

    @media screen and (max-width: 1200px) {
      bottom: 12px;
    }

    @media screen and (max-width: 700px) {
      bottom: 0px;

      .bark {
        fill: #fff;
      }

      &__text {
        display: none;
      }
    }

    svg {
      margin-left: 10px;
    }
  }

  .slick-arrows_wrapper {
    width: auto;
    @include page-side(padding-left, 20);
    padding-right: 50px;

    @media screen and (max-width: $page-width + 20) {}
  }
}

.detail-header {
  position: relative; //height: 100vh;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  &--text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #FFF;
  }

  &--text {
    .slick-slider {
      max-width: 660px;
      margin: auto;
    }

    .relative {
      height: 100%;
    }
  }

  &--bg {
    max-width: 100%;
    overflow: hidden;
    height: 100vh;

    img,
    picture {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__title {
    /* JASAN: */
    font-family: ChronicleDisplay-Semi;
    font-size: 145.8px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 113.4px;
    text-shadow: 0 2px 31px rgba(0, 0, 0, 0.50);
    padding-top: 20px;

    @media screen and (max-width: 1300px) {
      font-size: 120px;
    }

    @media screen and (max-width: 800px) {
      font-size: 100px;
    }

    @media screen and (max-width: 400px) {
      font-size: 70px;
    }
  }

  &__scroll-down {
    position: absolute;
    bottom: 30px;
    color: #FFF;
    cursor: pointer;

    svg {
      -webkit-animation: pulse 1.5s infinite;
      will-change: transform;
    }

    @include page-side(right, 20);

    .bark {
      fill: #FEFEFE;
    }

    @media screen and (min-width: 1400px) {
      right: 40px;
    }

    @media screen and (max-width: 1200px) {
      bottom: 12px;
    }

    @media screen and (max-width: 700px) {
      .bark {
        fill: none;
      }
    }

    @media screen and (max-width: 430px) {
      display: none;
    }

    svg {
      margin-left: 10px;
    }
  }

  &__numbers {
    position: absolute;
    color: #FFF;
    @include page-side(left);
    bottom: 0;

    @media screen and (max-width: 430px) {
      margin: auto;
      padding-right: 10px;
      right: 0
    }

    @media screen and (min-width: 1400px) {
      left: 40px;
    }
  }

  &__number {
    padding: 0 30px 34px 20px;
    display: inline-block;
    position: relative;

    &.active {
      text-decoration: none;

      &:after {
        content: '';
        width: 60%;
        position: absolute;
        bottom: 0;
        left: auto;
        right: auto;
        background: #b08d71;
        height: 3px;
        left: 24px;
      }
    }

    &__id {
      display: inline-block;
      font-family: FreightBigProBold-Regular;
      font-size: 14.4px;
      letter-spacing: 0;
      position: relative;
      vertical-align: top;
    }

    &__line {
      display: inline-block;
      height: 64px;
      width: 1px;
      transform: rotate(38deg) scaleY(1);
      background: #FFF;
      margin: -9px 12px -9px 8px;
      will-change: transform;
      @include animation(transform);
    }

    &__name {
      /* Jasan: */
      font-family: ChronicleDisplay-Bold;
      font-size: 15px;
      letter-spacing: 0.5px;
      vertical-align: bottom;
    }

    .hover &:hover {
      .detail-header__number__line {
        transform: rotate(38deg) scaleY(1.3);
      }
    }

    @media screen and (max-width: 1400px) {
      padding: 0 30px 28px 20px;
    }

    @media screen and (max-width: 1300px) {
      padding: 0 20px 20px 20px;

      &__line {
        height: 54px;
      }
    }

    @media screen and (max-width: 1100px) {
      padding: 0 10px 20px 20px;

      &__line {
        transform: rotate(43deg) scaleY(.8);
        margin: -9px 1px -9px 2px;
      }

      .hover &:hover {
        .detail-header__number__line {
          transform: rotate(43deg) scaleY(1);
        }
      }
    }
  }
}

.slick-arrows {
  max-width: 100px;
  margin: auto;
  white-space: nowrap;

  >div {
    display: inline-block !important;
    cursor: pointer;
    padding: 14px 14px 8px;
    line-height: 18px;

    .slick-arrows_wrapper--big & {
      padding: 19px;
    }
  }

  &_wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #FFF;
    display: block;
    width: 30%;
    will-change: unset;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 0;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      left: 0;
      border-width: 0 0 40px 30px;
      transform: rotate(360deg) translate(-30px, 0);
      will-change: unset;
      border-color: #FFF transparent;
    }

    &:after {
      right: 0;
      border-width: 40px 0 0 30px;
      transform: rotate(360deg) translate(30px, 0);
      will-change: unset;
      border-color: transparent transparent transparent #FFF;
    }

    &--big {
      &:before {
        border-width: 0 0 56px 30px;
      }

      &:after {
        border-width: 56px 0 0 30px;
      }
    }

    &--right {
      left: auto;
      right: 0;
    }
  }
}

.picture-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
}

picture {
  display: block;
}

.relative {
  position: relative;
}

.center {
  text-align: center;
}

img {
  max-width: 100%;
}

.select-house {
  &_wrapper {
    @extend .flex-distribute;
  }

  width: calc(33% - 60px); // TODO: Responsive value
  position: relative;
  margin-top: 125px; // TODO: Responsive value

  &:nth-child(2) {
    margin-top: 0;
  }

  @media screen and (max-width:1100px) {
    width: calc(33% - 40px);
  }

  @media screen and (max-width:1000px) {
    width: calc(33% - 20px);
  }

  @media screen and (max-width:660px) {
    margin-top: 0;
    width: 80%;

    &_wrapper {
      max-width: 450px;
      margin: auto;
    }

    max-width: 320px;
    margin-bottom: 50px;
  }

  &__heading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    color: #FFF;
    height: 1em;
    text-align: center;
    width: 100%;
    padding: 0;
    z-index: 2;
  }

  &__more {
    position: absolute;
    background: #FFF;
    display: block;
    z-index: 3;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 0;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      left: 0;
      border-width: 0 0 56px 30px;
      transform: rotate(360deg) translate(-30px, 0);
      border-color: #FFF transparent;
    }

    &:after {
      right: 0;
      width: 2em;
      height: 1px;
      background: #685141;
      vertical-align: middle;
      display: inline-block;
      position: relative;
      border: none;
      margin-left: 19px;
      transform: none;
      transform: scaleX(1);
      transform-origin: right;
      @include animation(transform);
    }

    .hover &:hover:after {
      transform: scaleX(1.3);
    }

    @media screen and (max-width:660px) {
      &:after {
        display: none;
      }
    }

    left: auto;
    right: -10px;
    bottom: -15px;
    padding: 32px 0 5px 14px; // TODO: Responsive value
    /* Prohlédnout: */
    @extend .h4;
    color: #685141;
    width: auto;
  }

  a {
    display: block;
    height: 100%;
  }

  a:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    opacity: 0;
    @include animation(opacity);
    position: absolute;
    z-index: 1;
    will-change: opacity;
    display: block;
  }

  .hover & a:hover {
    &:before {
      opacity: 1;
    }
  }
}

.team-member {
  &_wrapper {
    @extend .flex-distribute;

    @media screen and (max-width: 1000px) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  // margin-top: 55px; // TODO: Responsive value
  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 55px;

    @media screen and (max-width: 1000px) {
      margin-top: 0px;
    }
  }

  &:nth-child(3) {
    margin-top: 110px;

    @media screen and (max-width: 1000px) {
      margin-top: 0px;
    }
  }

  &:nth-child(4) {
    margin-top: 165px;

    @media screen and (max-width: 1000px) {
      margin-top: 0px;
    }
  }

  width: calc(25% - 55px);

  @media screen and (max-width: 1200px) {
    width: calc(25% - 26px);
  }

  @media screen and (max-width: 1000px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: $fourbecameone) {
    width: 100%;
    margin-top: 0; // TODO: Responsive value
  }

  &__heading {
    @extend .h4;
    color: #000000;
    padding: 45px 0 14px;
  }

  &__content {
    max-width: 258px;
    margin: auto;
    padding-bottom: 35px;
  }
}

.our-mission {
  @include section-size(padding-top, 0, 0);

  bdi {
    color: #89766a;

    &:before {
      background: #89766a;
    }
  }

  &__p {
    color: #FFF;
    /* Společným cílem všec: */
    font-family: ChronicleDisplay-Light;
    font-size: 24px;
    letter-spacing: 0.033em;
    line-height: 1.3em;
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 800px) {
      font-size: 20px;
    }

    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  }

  &__bg {
    padding: 0;
    @include section-size(padding-top);
    padding-bottom: 300px;
    background: $primary-color;
    color: #FFF;
    max-width: none;
    @include section-side(margin-left, 0, -1);
    @include section-side(margin-right, 0, -1);

    &__content {
      @extend %page-element;
    }
  }

  &__team {
    margin-top: -220px;
  }
}

.brown-card {
  padding: 42px 35px;
  color: #FFF;
  background: $primary-color;

  &__line {
    background: #FFF;
    width: 30px;
    height: 1px;
    display: inline-block;

    &--with-space {
      margin-bottom: 2em;
    }
  }

  p {
    color: #FFF;
  }

  &--jasan {
    background: $jasan;
  }

  &--lipa {
    background: $lipa;
  }

  &--buk {
    background: $buk;
  }
}

.ilona {
  position: relative;
  margin-bottom: 50%;

  &_wrapper {
    max-width: 560px; //width: 600%;
    position: relative;
    padding-right: 250px;
  }

  &__img {
    width: 100%;
  }

  &__text {
    position: absolute;
    top: 20%;
    left: 70%;
    height: 100%;
    width: 100%;
    @extend .shadow;

    &__content {
      left: 35px;
      bottom: 42px;
      position: absolute;
      width: calc(100% - 70px);

      &__title {
        font-weight: 700;
        color: #FFF;
        padding-bottom: 1.3em;
        display: block;
      }

      &__text {
        padding-bottom: 40px;
        color: #FFF;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    &_wrapper {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 100%;

    &_wrapper {
      max-width: 310px;
      padding-right: 0px;
    }

    &__img {
      left: -20px;
      position: relative;
      width: calc(100% + 20px);
    }

    &__text {
      top: 56%;
      left: 0;
      width: calc(100% + 20px);
    }
  }
}

.contact-and-sale {
  &__contact-content {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;

    &_wrapper {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    &__column {
      padding-right: 15px;
    }
  }

  &__ilona-img {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1000px) {

    &__contact-content_wrapper,
    &__ilona-img {
      width: 100%;
    }
  }
}

.service {
  &_wrapper {}

  width: 284px;
  display: inline-block;
  padding: 45px 30px;

  &__title {
    /* Labor 13: */
    @extend .h4;
    padding: 45px 0 14px;
  }

  @media screen and (max-width:660px) {
    padding: 20px 10px;
  }
}

.mobile-responsive {
  @media screen and (max-width: 660px) {
    >div {
      min-width: 100%;
    }
  }
}

.news {
  &_wrapper {
    position: relative;
  }

  &_slider {
    max-width: 330px;
  }

  max-width: 300px;
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;

  &__content {
    padding: 20px;

    &__time {
      /* 12 / 02 / 2018: */
      font-family: ChronicleDisplay-Semi;
      font-size: 10px;
      color: #9B9B9B;
      letter-spacing: .182em;
      padding: 20px 0 8px;
      display: block;
    }

    &__title {
      font-size: 24px;
      line-height: 1.3em;
      padding-bottom: .25em;
    }
  }

  &__arr {
    position: absolute;
    right: 0;
    top: 150px;
    height: 50px;
    width: 50px;
    background: #000;
    color: #FFF;
    display: block;
    cursor: pointer;

    &.slick-disabled {
      display: none !important;
    }

    &--left {
      left: 0px;
      right: auto;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    @media screen and (max-width: 600px) {
      height: 32px;
      width: 32px;
      top: 120px;
    }
  }
}

.partner {
  &_wrapper {
    position: relative;
  }

  &_slider {
    max-width: 290px;
  }

  max-width: 260px;
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  position: relative;
  color: #FFF;
  @extend .shadow;

  p {
    color: #FFF;
    padding-bottom: 0;
  }

  &__content {
    padding: 20px;
    position: absolute;
    bottom: 0;

    &__title {
      font-size: 24px;
      line-height: 1.3em;
      padding-bottom: .5em;
    }
  }

  &__arr {
    position: absolute;
    right: 0;
    top: 150px;
    height: 50px;
    width: 50px;
    background: #000;
    color: #FFF;
    display: block;
    cursor: pointer;

    &.slick-disabled {
      display: none !important;
    }

    &--left {
      left: 0px;
      right: auto;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    @media screen and (max-width: 600px) {
      height: 32px;
      width: 32px;
      top: 120px;
    }
  }

  @media screen and (max-width: 660px) {
    max-width: 210px;
    margin-right: 20px;

    &_slider {
      max-width: 210px;
    }
  }
}

.simple-line {
  /* ČÍST VÍCE: */
  @extend .h4; //color: #F6F5F3;

  &:after {
    content: '';
    width: 1.3em;
    height: 1px;
    background: #685141;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    border: none;
    margin-left: .6em;
    transform: scaleX(1);
    transform-origin: left;
    @include animation(transform)
  }

  &--white {
    &:after {
      background: #FFF;
    }
  }

  .hover &:hover {
    &:after {
      transform: scaleX(1.3);
    }
  }
}

$contact-popup-one-layout: 800px;

.contact-popup {
  &_wrapper {
    //display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 910px;
    max-width: 100%;
    height: 100%;
    overflow: auto;
    background: #FFF;
    left: 100%;
    will-change: transform;
    /* Rectangle 35: */
    box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.17);
    z-index: 500;
    -webkit-overflow-scrolling: touch;
  }

  @extend .flexbox-wrapper;
  min-height: 100%;

  &__form {
    width: 60%;
    padding: 60px 100px;

    li {
      width: 100%;
      padding: 0
    }

    ul {
      padding: 0;
      margin-top: 50px;
    }

    button {
      display: block;
      width: 100%;
      margin-top: 50px;
    }

    @media screen and (max-width: 1300px) {
      padding: 45px 70px;
    }

    @media screen and (max-width: 1000px) {
      padding: 45px 40px;
    }
  }

  &__image {
    position: relative;
    min-width: 380px;
    min-height: 300px;
  }

  @media screen and (max-width: $contact-popup-one-layout) {
    &_wrapper {
      width: 520px;
    }

    &__form,
    &__image {
      width: 100%;
      min-width: 100%;
    }

    &__image {
      min-height: 0;

      .picture-wrapper {
        display: none;
      }
    }
  }
}

.contact-card {
  padding: 40px;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__image {
    border-radius: 50%;
    overflow: hidden;
    width: 70px;
    height: 70px;
    display: inline-block;
  }

  &__content {
    color: #FFFFFF;
    display: inline-block;
    width: calc(100% - 90px);
    padding-left: 35px;

    &__title {
      /* KONTAKTNÍ OSOBA: */
      opacity: 0.7;
      font-family: Gilroy-Bold;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 19px;
      display: block;
    }

    &__name {
      /* Ilona: */
      font-family: Gilroy-Bold;
      font-size: 13px;
      letter-spacing: 2.89px;
      display: block;
    }

    &__line {
      background: #676767;
      height: 1px;
      margin: 13px 0;
      width: 26px;
    }

    &__link {
      /* sales@strane.cz: */
      font-family: Gilroy-Regular;
      font-size: 11px;
      letter-spacing: 1px;
      line-height: 19px;
      display: block;
    }
  }

  @media screen and (max-width: $contact-popup-one-layout) {
    position: relative;
  }
}

.table-control {
  display: table;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  @extend .h4;

  .tr {
    display: table-row;
    border-bottom: 1px solid rgba(174, 174, 174, .3);
    background: #FFF;
    @include animation(background);

    .hover &:hover {
      background: #f5f4f5;
    }
  }

  .td,
  .th {
    display: table-cell;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-right: 6px;
    &--pdf {
      width: 180px;

      a {
        padding: 7px 2px;
        display: block;
      }
    }

    &--button {
      width: 110px;
    }
  }

  .th {
    background: #b08d71;
    color: #FFF;
    cursor: pointer;

    .up,
    .down {
      fill: rgba(0, 0, 0, 0.15);
    }

    &.asc {
      .up {
        fill: rgba(0, 0, 0, 0.57);
      }
    }

    &.desc {
      .down {
        fill: rgba(0, 0, 0, 0.57);
      }
    }
  }

  .line-break {
    width: 0;
    padding: 0;
  }

  @media screen and (max-width: 880px) {
    display: block;

    .tr {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      align-content: space-between;
    }

    .td,
    .th {
      display: block;
      flex-grow: 1;
      align-items: stretch;

      &--pdf {
        width: 50%;
      }

      &--button {
        width: 50%;
      }
    }

    .th {

      &--pdf,
      &--button {
        display: none;
      }
    }

    .line-break {
      width: 100%;
    }
  }
}

.choose-ribbon {
  position: absolute;
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotate(-90deg);
  transform-origin: center bottom;
  @extend .h4;
  color: #FFF;
  background: #b08d71;
  height: 50px;
  padding: 19px;
  z-index: 50;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:before {
    left: 0;
    border-width: 0 0 50px 30px;
    transform: rotate(360deg) translate(-30px, 0);
    border-color: #b08d71 transparent;
  }

  &:after {
    right: 0;
    border-width: 50px 0 0 30px;
    transform: rotate(360deg) translate(30px, 0);
    border-color: transparent transparent transparent #b08d71;
  }

  @media screen and (max-width: 1300px) {
    height: 40px;
    padding: 14px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.topbar {
  @extend %page-element;
  text-align: right;
  color: #FFF;
  will-change: transform;
  @extend .h4;

  &_wrapper {
    position: fixed;
    top: 33px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 25;
  }

  &__logo {
    float: left;
    transform-origin: left top;
    position: relative;
    top: -5px;

    @media screen and (max-width: 900px) {
      transform: scale(.95);
    }

    @media screen and (max-width: 400px) {
      transform: scale(.75);
      top: 0;
    }
  }

  &__menu {
    margin-left: 30px;
    padding-top: 7px;
    padding-bottom: 7px;

    &__svg-wrapper {
      margin-left: 14px;
      display: inline-block;

      svg:first-child {
        position: absolute;
        transform: scale(0);
      }
    }

    @media screen and (max-width: 480px) {
      margin-left: 18px;
    }
  }

  &__lng {
    padding-top: 7px;
    padding-bottom: 7px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    >div {
      position: relative;
    }

    span:after {
      content: '';
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 2.5px 0 2.5px;
      border-color: #ffffff transparent transparent transparent;
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 5px;
    }

    a {
      display: block;
      padding: 5px 0;
    }

    .sub {
      opacity: 0;
      position: absolute;
      top: 15px;
      left: 0px;
      @include animation(all);
    }
  }

  @media screen and (min-width: 1400px) {
    max-width: calc(100% - 80px);
  }
}

.side-heading {
  position: absolute;
  @extend .h5;
  transform-origin: center bottom;
  transform: translate(-50%, -50%) rotate(-90deg);

  &__line {
    width: 40px;
    height: 1px;
    background: #000;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &__number {
    transform: rotate(90deg);
    display: inline-block;
    font-family: FreightBigProBold-Regular;
    font-size: 14.4px;
    letter-spacing: 0;
    position: relative;
    bottom: -1px;
  }

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.second-home {
  transform-origin: right top;
  position: absolute;
  transform: translate(-15px, 55px) rotate(90deg);
  right: 0;
  font-family: ChronicleDisplay-Light;
  font-size: 24px;
  letter-spacing: 0.03em;
  white-space: nowrap;

  &__line {
    width: 90px;
    height: 1px;
    background: #000;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
  }

  @media screen and (max-width: 1300px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1100px) {
    transform: translate(-20px, 30px) rotate(90deg);
    font-size: 17px;
  }

  @media screen and (max-width: 900px) {
    transform: translate(-15px, 30px) rotate(90deg);
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

$grid-wrapper-width: 1200;
$hp-grid-width: percentage(475/$grid-wrapper-width);

.hp-grid {
  padding-top: 100px;
  padding-bottom: 230px;
  position: relative;

  &_section {
    overflow: visible;
  }

  &__side-heading {
    @extend .side-heading;
    left: -30px;
    top: 50%;
  }

  &__1 {
    width: $hp-grid-width;
    position: relative;
    margin-left: .5%;
    overflow: hidden;

    .slick-slider--simple,
    .slick-slider {
      width: 100%;
      position: relative;
    }

    img,
    picture {
      //width: $hp-grid-width;
    }
  }

  &__2 {
    position: absolute;
    width: percentage(446/$grid-wrapper-width);
    right: 0;
    bottom: 0;

    &__text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 276px;
      transform: translate(-50%, 0);
      background-image: url("../img/hp/grid/watermark.png");
      background-position: top right;
      background-size: 80% auto;
      background-repeat: no-repeat;
      @extend .shadow;

      &__title {
        font-size: 24px;
        padding-top: 1.5em;
      }
    }
  }

  &__3 {
    position: absolute;
    width: 273px;
    width: percentage(273/$grid-wrapper-width);
    right: 15.6%;
    top: 0;

    @media screen and (max-width: 1030px) {
      width: percentage(330/$grid-wrapper-width);
    }
  }

  @media screen and (max-width: 660px) {
    padding: 0;
    max-width: 450px;
    margin: auto;

    &__1 {
      width: 100%;
    }

    &__2 {
      position: relative;
      width: 100%;

      &__text {
        transform: translate(-20px, 30px);
        bottom: 0;
      }

      picture {
        width: calc(100% + 20px);
        position: relative;
        display: block;
      }
    }

    &__3 {
      position: relative;
      right: auto;
      width: auto;
      margin: 20px -20px 40px;

      img {
        width: 100%;
      }
    }
  }
}

.design-grid {
  position: relative;

  &--1 {
    padding-top: 88px;
    padding-top: 138px;
    padding-bottom: 81px;
  }

  &--2 {
    padding-top: 104px;
    margin-top: 60px;
  }

  &__side-heading {
    @extend .side-heading;
    left: -30px;
    top: 50%;
  }

  .text-block {
    &--1 {
      margin-left: percentage(180/$grid-wrapper-width);
      max-width: 50%;
      width: 500px;
      padding: 80px 90px;
      z-index: 5;
      background-image: url("../img/detail/wattarmark-jasan.png");
      background-position: top left;
      background-size: 34% auto;
      background-repeat: no-repeat;

      &.brown-card--lipa {
        background-image: url("../img/hp/grid/watermark.png");
        background-position: top right;
        background-size: 80% auto;
        background-repeat: no-repeat;
      }
    }

    &--2 {
      max-width: 50%;
      width: 288px;
      position: absolute;
      top: 10%;
      left: -10%;

      p {
        padding-bottom: 0;
      }
    }
  }

  &__img {
    &--1 {
      position: absolute;
      right: percentage(126/$grid-wrapper-width);
      width: 258px;
      width: percentage(258/$grid-wrapper-width);
      top: 0;
    }

    &--2 {
      position: absolute;
      width: 298px;
      width: percentage(298/$grid-wrapper-width);
      bottom: 0;
      left: 50%;
    }

    &--3 {
      position: absolute;
      width: 189px;
      width: percentage(189/$grid-wrapper-width);
      right: percentage(203/$grid-wrapper-width);
      top: 0;
    }

    &--4 {
      position: relative;
      width: 446px;
      width: percentage(466/$grid-wrapper-width);
      top: 0;
      margin-left: percentage(84/$grid-wrapper-width);
    }

    &--5 {
      position: absolute;
      width: 475px;
      width: percentage(475/$grid-wrapper-width);
      right: percentage(203/$grid-wrapper-width);
      bottom: 40px;
      right: 0;
      z-index: 5;
    }
  }

  &__fly-text {}

  @media screen and (max-width: 1100px) {
    .text-block {
      &--1 {
        padding: 45px 50px;
        max-width: 43%;
        margin-left: 0%;
      }

      &--2 {
        max-width: 70%;
        left: -15%;
      }
    }

    &__img {
      &--1 {
        right: 9%;
        width: percentage(380/$grid-wrapper-width);
      }

      &--2 {
        width: percentage(308/$grid-wrapper-width);
        left: 30%;
        bottom: -5%;
      }

      &--3 {
        width: percentage(210/$grid-wrapper-width); //right: percentage(203/$grid-wrapper-width);
      }

      &--4 {
        position: relative;
        width: 446px;
        width: percentage(466/$grid-wrapper-width);
        top: 0;
        margin-left: percentage(84/$grid-wrapper-width);
      }

      &--5 {
        position: absolute;
        width: 475px;
        width: percentage(475/$grid-wrapper-width);
        right: percentage(203/$grid-wrapper-width);
        bottom: 40px;
        right: 0;
        z-index: 5;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .text-block {
      &--1 {}

      &--2 {
        padding: 27px;
        max-width: 80%;
      }
    }

    &__img {
      &--1 {
        width: percentage(430/$grid-wrapper-width);
      }

      &--2 {
        width: percentage(338/$grid-wrapper-width);
      }

      &--3 {}

      &--4 {}

      &--5 {}
    }
  }

  @media screen and (max-width: 890px) {
    .text-block {
      &--1 {}

      &--2 {
        padding: 27px;
        max-width: 80%;
      }
    }
  }

  @media screen and (max-width: 660px) {
    padding: 0;
    max-width: 450px;
    margin: auto;

    .text-block {
      &--1 {
        margin-left: 0;
        max-width: none;
        width: calc(100% + 20px);
        padding: 30px 40px;
        margin-bottom: 40px;
      }

      &--2 {
        margin-left: 0;
        max-width: none;
        width: 80%;
        position: absolute;
        top: 0px;
        left: -20px;
      }
    }

    &__img {
      margin-bottom: 40px;

      &--1 {
        position: relative;
        right: auto;
        width: 100%;
        left: -20px;
      }

      &--2 {
        position: relative;
        right: auto;
        width: 100%;
        left: 0px;
      }

      &--3 {
        position: relative;
        width: 100%;
        right: 0
      }

      &--4 {
        position: relative;
        width: 100%;
        margin-left: 0
      }

      &--5 {
        position: relative;
        width: 475px;
        width: 100%;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding-top: 90px;
      }
    }
  }
}

.partners {
  position: relative;

  &__side-heading {
    @extend .side-heading;
    right: -64px;
    @include section-size(top);
    transform: translate(-50%, -50%) rotate(-90deg);
    transform-origin: right bottom;
  }

  &__content {
    @include section-size(padding-bottom);
  }
}

.architecture {
  position: relative;

  &__side-heading {
    @extend .side-heading;
    right: -80px;
    transform: translate(-55%, -50%) rotate(-90deg);
    transform-origin: right bottom;
  }
}

.services {
  position: relative;

  &__side-heading {
    @extend .side-heading;
    right: -110px;
    transform: translate(-55%, 685%) rotate(-90deg);
    transform-origin: right bottom;
  }
}

.statistics {
  padding-top: 80px;

  .h4 {
    display: block;
  }

  &__block {
    @media screen and (max-width: 600px) {
      padding-bottom: 20px;
    }
  }
}

$location-gallery-width: 325px;

.location {
  padding-top: 50px;

  &__map {
    flex: 3;
    padding-right: 50px;
    position: relative;
    left: -100px;

    @media screen and (max-width: $page-width + 130px) {
      left: 0;
    }
  }

  &__side-heading {
    top: 15px;
    left: 30px;
    transform-origin: right bottom;
    transform: translate(-100%, -50%) rotate(-90deg);
  }

  &__gallery {
    max-width: $location-gallery-width;

    picture,
    img {
      width: $location-gallery-width;
    }

    &__side-heading {
      @extend .side-heading;
      left: -30px;
      top: 50%;
    }
  }

  @media screen and (max-width: 1000px) {

    &__map,
    &__gallery {
      min-width: 100%;
      padding: 0;
      margin-left: -20px;
      margin-right: -20px;
    }

    &__gallery {
      padding: 60px 20px 0;
    }
  }
}

$interier-gallery-width: 325px;

.interier {
  padding-top: 70px;

  &__control {
    flex: 3;
    position: relative;
    margin-top: 70px;
    padding-right: 50px;

    &__content {
      position: relative;

      @media screen and (max-width: 1200px) {
        max-width: 500px;
        margin: auto;
      }
    }
  }

  &__gallery {
    max-width: $interier-gallery-width;

    picture,
    img {
      width: $interier-gallery-width;
    }

    &__side-heading {
      @extend .side-heading;
      left: -30px;
      top: 50%;
    }
  }

  &__info-card {
    &__heading {
      @extend .h4;
      display: block;
    }
  }

  @media screen and (max-width: 1000px) {

    &__control,
    &__gallery {
      min-width: 100%;
      padding: 0;
    }

    &__control {
      margin-left: -20px;
      margin-right: -20px;
    }

    &__gallery {
      padding: 60px 20px 0;
    }
  }
}

.split-slider {
  overflow: hidden;
  position: relative;

  &_wrapper {
    position: relative;
  }

  &__area {
    padding: 0;
    margin: 0;
    width: 100%;
    left: 0px;
    top: 0;
    bottom: 0;
    position: absolute;
  }

  &__color-switch {
    border: 2px solid transparent;
    display: inline-block;
    @include animation(all);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transform: scale(.8);
    margin-right: 4px;
    cursor: pointer;

    &_wrapper {
      top: 20px;
      right: 20px;
      white-space: nowrap;
      position: absolute;
      z-index: 10;
      display: none;

      @media screen and (max-width: 900px) {
        display: block;
      }
    }

    &--dark {
      background: #FFF;
    }

    &--light {
      background: #000;
    }

    &.active {
      border: 2px solid $buk;
      transform: scale(1);
    }
  }

  &__draggable {
    border-left: 1px solid white;
    height: 100%;
    width: 1px;
    left: 50%;
    position: relative;
    z-index: 5;
    cursor: ew-resize;

    @media screen and (max-width: 900px) {
      display: none;
    }

    &__handle {
      height: 58px;
      width: 58px;
      top: 0;
      bottom: 0;
      left: -58px;
      right: -58px;
      margin: auto;
      position: absolute;
      margin: auto;
      background: #FFF;
      border-radius: 50%;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &__box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 4;
    overflow: hidden;

    div {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__content {
      background-position: center;
      background-size: cover;

      .selector div {
        border: 2px solid #FFF;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
        transform: scale(.9);

        .hover &:hover {
          transform: scale(1);
        }

        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }

  &_wrapper {
    background: #000;
    position: relative;

    &--room {
      width: 80%;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;

      .split-slider {
        padding-top: 57.80%;
      }

      .split-slider__box--light {
        .split-slider__box__content {
          background-image: url("../img/detail/room/room-dark.jpg");
        }
      }

      .split-slider__box--dark {
        .split-slider__box__content {
          background-image: url("../img/detail/room/room-light.jpg");
        }
      }
    }

    &--bathroom {
      width: 57.95%;
      z-index: 2;
      margin: 0;

      .split-slider {
        padding-top: 135.99%;
      }

      .split-slider__box--light {
        .split-slider__box__content {
          background-image: url("../img/detail/bathroom/shower1.jpg");
        }
      }

      .split-slider__box--dark {
        .split-slider__box__content {
          background-image: url("../img/detail/bathroom/shower2.jpg");
        }
      }
    }

    @media screen and (max-width: 1300px) {

      //margin: auto;
      &--room {
        width: calc(100% - 50px);
      }

      &--bathroom {
        width: 72.44%;
      }
    }

    @media screen and (max-width: 900px) {
      margin: auto;

      &--room {
        width: 100%;
        position: relative;
      }

      &--bathroom {
        width: 100%;
      }
    }
  }
}

.split-slider-switcher {
  padding-top: 35px;

  .h4 {
    text-transform: uppercase;
  }
}

.slick-slider {
  &--text {
    padding-top: 40px;
  }

  &--location,
  &--text,
  &--interier {
    max-width: $location-gallery-width;
    margin-left: auto;
    margin-right: auto;
  }
}

h2 span.wtf {
  /* Nebo konkrétní byt v: */
  font-family: ChronicleDisplay-Light;
  font-size: 18px;
  color: #9B9B9B;
  letter-spacing: 0.6px;
  line-height: 31.2px;
  padding: 0 0 40px 0;

  @media screen and (max-width: 740px) {
    padding: 0 0 10px 0;
  }
}

.apl-sign {
  color: rgba(255, 255, 255, .5);

  span {
    padding-left: 5px;
  }

  a {
    opacity: .5;
    color: #FFF;
    @include animation(opacity);

    .hover &:hover {
      opacity: 1;
    }
  }
}

.copyright {
  @media screen and (max-width: 530px) {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {

  .tracy-panel,
  #tracy-debug {
    display: none !important;
  }

  .tracy-panel {
    display: none;
  }
}

// Cookie bar
.cookie-wrapper {
  position: relative;
}

.cookie-bar {
  $layout-change: 600px;
  background: $primary-color; //font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 1em 1.8em;
  display: flex;
  z-index: 1200;
  font-size: 14px; //border-top: 4px solid $gold-lighter;
  box-shadow: -2px 0px 44px 0 rgba(0, 0, 0, 0.17);

  @media screen and (max-width: $layout-change) {
    font-size: 12px;
    padding: 0.75em 1.2em;
  }

  >div {
    align-self: center;
  }

  .controls {
    text-align: center;
    padding-left: 0.5em;
    margin: 0 0 0 auto;
  }

  button {
    @include animation(all);
    @extend .btn--small;

    &.enable {
      min-width: 120px;

      @media screen and (max-width: $layout-change) {
        min-width: 90px;
      }
    }
  }
}

.floor-picker {
  width: 100%;
  position: relative;
  padding: 40px 90px;

  &__svg-wrapper {
    padding-top: percentage(305/1330);
    position: relative;
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__trigger {
    fill: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  [data-floor-color] {
    fill: #c9aa8f;
    opacity: 0;
    @include animation(opacity);

    .hover &.hover {
      opacity: .4;
    }

    &.active {
      opacity: 1 !important;
    }
  }

  &__list {
    position: absolute;
    top: 40px;
    right: 20px;
    bottom: 40px; //transform: translateY(-50%);

    span {
      @extend .h4;
      color: #9B9B9B;
      @include trigger-with-active();
    }

    li {
      position: absolute;
      right: 10px;
      white-space: nowrap;
    }

    &--jasan {
      li {
        &:nth-child(1) {
          bottom: 58%;
        }

        &:nth-child(2) {
          bottom: 35%;
        }

        &:nth-child(3) {
          bottom: 13%;
        }
      }
    }

    &--buk {
      li {
        &:nth-child(1) {
          bottom: 63%;
        }

        &:nth-child(2) {
          bottom: 43%;
        }

        &:nth-child(3) {
          bottom: 23%;
        }

        &:nth-child(4) {
          bottom: 3%;
        }
      }
    }

    &--lipa {
      li {
        &:nth-child(1) {
          bottom: 50%;
        }

        &:nth-child(2) {
          bottom: 14%;
        }
      }
    }

    li:after {
      content: '';
      display: block;
      width: 8px;
      background: #9B9B9B;
      height: 1px;
      position: absolute;
      bottom: 0;
      margin: auto;
      left: -7px;
      top: 0;
      bottom: 0;
    }
  }

  @media screen and (max-width: 740px) {
    padding: 0px 40px 40px;

    &__list {
      position: relative;
      right: 0;
      top: 0;
      transform: none;
      text-align: center;

      >li {
        display: inline-block !important;
        width: auto;
        bottom: 0 !important;
        position: relative;
      }

      li:not(:last-child):after {
        height: 10px;
        width: 1px;
        margin: auto;
        left: auto;
        top: 0;
      }
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0px 0px 40px;
  }
}

.flexbox-wrapper h2 {
  padding-right: 40px;
}

.slick-slider--interier {
  img:nth-child(1) {
    display: none;
  }

  .light {
    img:nth-child(1) {
      display: block;
    }

    img:nth-child(2) {
      display: none;
    }
  }
}

[data-paralax-animation] {
  will-change: transform;
}

[data-split-slider-place] [data-split-slider-slideid] {
  cursor: pointer;
}

.trigger {
  @extend .h4;
  @include trigger-with-active();

  &:after {
    content: '';
    display: block;
    background: #000000;
    @include animation(background);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  .hover &:hover:after {
    background: $jasan;
  }

  &.active:after {
    background: $buk;
  }
}

.readmorecontrol__btn {
  white-space: nowrap;

  &__wrapper {
    position: relative;
    display: inline-block
  }

  &__second {
    position: absolute;
    right: 0;
  }
}

$section-side: 10px;
$section-side-1400: 10px;
$section-side-1000: 10px;

@mixin section-side($atribute,
$offset: 0,
$multiply: 1) {
  #{$atribute}: $multiply * ($section-side + $offset);

  @media screen and (max-width: 1400px) {
    #{$atribute}: $multiply * ($section-side-1400 + $offset)
  }

  @media screen and (max-width: 1000px) {
    #{$atribute}: $multiply * ($section-side-1000 + $offset)
  }

  @media screen and (max-width: 1000px) {
    #{$atribute}: $multiply * ($section-side-1000 + $offset)
  }
}

section {
  @include section-side(padding-left);
  @include section-side(padding-right);
}

#ham {
  @include section-side(right);
  position: absolute;
}

#image-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  rect {
    fill: transparent;
    opacity: 0.001;
    cursor: pointer;
  }
}