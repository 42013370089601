@mixin font-size($px) {
  font-size: #{$px/$font-size-base}rem;
}

@mixin animation($a, $b: .3s, $c: ease-out) {
  transition: $a $b $c;
}

@mixin media($media) {
  @media screen and ($media) {
    @content ;
  }
}

@mixin grid($gutter: $default-gutter, $media-queries: false) {
  padding: $gutter / 2;
  >li {
    padding: $gutter / 2;
  }
  &.inner {
    margin: -$gutter;
  }
  @if($media-queries) {
    @each $key,
    $value in $media-queries {
      @media screen and (max-width: $key) {
        padding: $value / 2;
        >li {
          padding: $value / 2;
        }
        &.inner {
          margin: -$value;
        }
      }
    }
  }
}

.flex-distribute {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: space-between;
  justify-content: space-between;
  &.reverse {
    flex-direction: row-reverse;
  }
}

.shadow {
  box-shadow: 0 22px 18px -14px #00000048;
}

@mixin trigger-with-active () {
  @include animation(color);
  position: relative;
  padding-bottom: 3px;
  cursor: pointer; //&:after{
  //  content: '';
  //  display: block;
  //  background: $buk;
  //  transform: scaleX(0);
  //  transform-origin: left;
  //  @include animation(transform);
  //  height: 1px;
  //  width: 100%;
  //  position: absolute;
  //  bottom: -1px;
  //  left: 0;
  //}
  .hover &:hover {
    color: $jasan;
  }
  &.active {
    color: $buk; //  &:after{
    //    transform: scaleX(1);
    //  }
  }
}

$section-side: 10px;
$section-side-1400: 10px;
$section-side-1000: 10px;
@mixin section-side($atribute, $offset: 0, $multiply: 1) {
  #{$atribute}: $multiply * ($section-side + $offset);
  @media screen and (max-width: 1400px) {
    #{$atribute}: $multiply * ($section-side-1400 + $offset)
  }
  @media screen and (max-width: 1000px) {
    #{$atribute}: $multiply * ($section-side-1000 + $offset)
  }
  @media screen and (max-width: 1000px) {
    #{$atribute}: $multiply * ($section-side-1000 + $offset)
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(.95);
  }
  79% {
    transform: scale(1);
  }
  100% {
    transform: scale(.95);
  }
}