.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}
.hp-header--bg{
	background: #000;
}
.slick-track,
.slick-list {
-webkit-perspective: 2000;
-webkit-backface-visibility: hidden;

}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}
	display: none;
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

/// custom changes
.slick-slide {
	.caption {
		opacity: 0;
		transform: translateY(-5px);
		position: absolute;
		bottom: -33px;
		right: 0;
		@include animation(all, .3s, ease);
	}
}

.slick-active {
	@media screen and (min-width: 550px) {
		.caption {
			opacity: 1;
			transform: translateY(0px);
			transition-delay: .4s;
		}
	}
}

.slider-wrapper {
	clear: both;
}

.slider-content {
	max-width: 100%;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin: auto;
}

.slick-slider {
	position: relative;
}

.next-arr,
.prev-arr {
	position: absolute;
	bottom: 5vh;
	width: 70px;
	height: 70px;
	z-index: 50;
	right: 50px;
	cursor: pointer;
	.bg {
		fill: #000;
		@include animation(fill);
	}
	.arr {
		stroke: $primary-color;
		@include animation(stroke);
	}
	.hover &:hover {
		.bg {
			fill: $primary-color;
		}
		.arr {
			stroke: $secondary-color;
		}
	}
}

.prev-arr {
	right: 120px;
}

.slick-dots {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 60px;
	margin: auto;
	li {
		display: inline-block;
		float: none;
		vertical-align: baseline;
		cursor: pointer;
		/* 2: */
		//@include font-numbers();
		color: #000000;
		border: 1px solid $primary-color;
		padding: 1px;
		font-size: 0;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 4px;
		button {
			border-radius: 50%;
			overflow: hidden;
			padding: 3px;
			color: transparent;
			@include animation(color);
			transform: none;
			&:before {}
		}
		&.slick-active button {
			background: #FFF;
		}
	}
}

.slick-slider {
	&--simple,
	&--location,
	&--interier {
		overflow: hidden;
		max-width: 100%;
		position: relative;
	}
}