$page-width: 1190px;
$page-width-half: $page-width/ 2;
$default-gutter: 20px;
$radius: 0;
$font-size-base: 16;
// Breakpoints
$bp-1000: 1000px;
$bp-940: 940px;
$bp-600: 600px;
// Colors
$border: gray;
$primary-color:#685142;
$secondary-color: #685142;
$jasan: #9b7c5e;
$lipa: #b08d71;
$buk: #856952;
//sizes
$section-size: 140px;
$section-size-1500: 120px;
$section-size-1300: 95px;
$section-size-1100: 80px;
$section-size-770: 70px;
$section-size-440: 50px;
$section-size-330: 45px;
@mixin section-size($atribute, $offset: 0, $multiply: 1) {
  #{$atribute}: $multiply * ($section-size + $offset);
  @media screen and (max-width: 1500px) {
    #{$atribute}: $multiply * ($section-size-1500 + $offset)
  }
  @media screen and (max-width: 1300px) {
    #{$atribute}: $multiply * ($section-size-1300 + $offset)
  }
  @media screen and (max-width: 1100px) {
    #{$atribute}: $multiply * ($section-size-1100 + $offset)
  }
  @media screen and (max-width: 770px) {
    #{$atribute}: $multiply * ($section-size-770 + $offset)
  }
  @media screen and (max-width: 440px) {
    #{$atribute}: $multiply * ($section-size-440 + $offset)
  }
  @media screen and (max-width: 330px) {
    #{$atribute}: $multiply * ($section-size-330 + $offset)
  }
}

@mixin page-side($atribute, $offset: 0) {
  #{$atribute}: calc(50% - #{$page-width-half} + #{$offset}px);
  @media screen and (max-width: 40px + $page-width) {
    #{$atribute}: 0px + $offset;
  }
}